<template>
  <div>
    <b-card>
      <b-card-text>{{ $t('Wlecome to system main page') }}</b-card-text>
      <b-button
        variant="primary"
        :to="{name:'dashboard'}"
      >
        {{ $t('Dashboard') }}
      </b-button>
      <b-card-text>
        <h2 class="text-primary">
          ******{{ auth.user_name }}******
        </h2>
        <b-link
          href="/"
          target="_blank"
        /></b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  computed: {
    auth() {
      return this.$store.getters['auth/GetAuth']
    },
    isAuth() {
      return this.$store.getters['auth/isAuth']
    },
  },
}
</script>

<style>

</style>
